import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'lte-content-header',
  template: `
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1 class="m-0 text-dark">{{title}} </h1>
        </div>
        <div class="col-sm-6"  *ngIf="breadcrumb">
          <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item">
              <a href="#">Home</a>
            </li>
            <li class="breadcrumb-item active">Starter Page</li>
          </ol>
        </div>
      </div>
    </div>
  </div>

`,
  styles: [``]
})
export class ContentHeaderComponent implements OnInit {

  @Input("title") title :string;
  @Input("breadcrumb") breadcrumb:boolean;

  constructor() { }

  ngOnInit() {
  }

}
