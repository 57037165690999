import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lte-header',
  template: `<nav class="main-header navbar navbar-expand bg-white navbar-light border-bottom">
   
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" data-widget="pushmenu" href="#"><i class="fa fa-bars"></i></a>
      </li>
    
    </ul>
  <ng-content></ng-content>
  </nav>`,
  styles: [`h1{color:red}`]
})
export class HeaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
