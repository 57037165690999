import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lte-content-row',
  template: `<div class="content">
  <div class="container-fluid">
    <div class="row">
      <ng-content></ng-content>

    </div>
  </div>
</div>`,
  styles: [``]
})
export class ContentRowComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
