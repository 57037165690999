import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'lte-card',
  template: `<div class="card card-primary card-outline">
    <div class="card-header">
        <h5 class="m-0">{{title}}</h5>
      </div>
      <div class="card-body">
        

        <ng-content></ng-content>
      </div>
  
  </div>
`,
  styles: [``]
})
export class CardComponent implements OnInit {

  @Input("title") title:string;
  constructor() { }

  ngOnInit() {
  }

}
