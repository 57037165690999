import { Component, OnInit, Input, HostBinding } from '@angular/core';



@Component({
  selector: '[lte-menu-item]',
  template: `
    <a [routerLink]="[router]" class="nav-link "[class.active]="hasChildren">
      <i class="nav-icon fa fa-th"></i>
      <p>
        {{title}} 
        
      </p>
    </a>
    
        
      <ng-content></ng-content>
        
      
  
`,
  styles: [``]
})
export class MenuItemComponent implements OnInit {

  @Input("hasChildren") hasChildren:boolean;
  @Input("router") router:string;
  @HostBinding('class.nav-item') someField: boolean = true;

  @Input("title") title:string;
  constructor() { }

  ngOnInit() {
  }

}
