import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lte-table',
  template: `<table class="table table-bordered">
  
    <ng-content></ng-content>
  
</table>`,
  styles: [``]
})
export class TableComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
