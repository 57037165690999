import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'lte-main-sidebar',
  template: `<!-- Main Sidebar Container -->
<aside class="main-sidebar sidebar-dark-primary elevation-4">
    <!-- Brand Logo -->
    <a [href]="link" class="brand-link">
      <img [src]="logo" alt="AdminLTE Logo" class="brand-image img-circle elevation-3"
           style="opacity: .8">
      <span class="brand-text font-weight-light">{{brand}}</span>
    </a>

    <!-- Sidebar -->
    <div class="sidebar">
      
      <!-- Sidebar Menu -->
      <nav class="mt-2">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
          <ng-content></ng-content>
        </ul>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>`,
  styles: [``]
})
export class MainSidebarComponent implements OnInit {
@Input("logo") logo :string;
@Input("brand") brand :string;
@Input("link") link :string;
  constructor() { }

  ngOnInit() {
  }

}
