import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'lte-small-card',
  template: `<div [ngClass]="styleObject">
  <div class="inner">
    <ng-content></ng-content>
  </div>
  <div class="icon">
    <i  [ngClass]="iconObject"></i>
  </div>
  <a *ngIf="showBottomLink" [routerLink]="[router]" class="small-box-footer">
    {{bottomLinkText}} <i class="fa fa-arrow-circle-right"></i>
  </a>
</div>`,
  styles: [``]
})
export class SmallCardComponent implements OnInit {

  @Input("showBottomLink") showBottomLink :boolean;
  @Input("bottomLinkText") bottomLinkText :string;
  @Input("faIcon") faIcon :string;
  @Input("style") style :string;
  @Input("router") router:string;
  
  iconObject : any;
  styleObject : any;
  constructor() { }

  ngOnInit() {
    this.iconObject = {'fa':true}
    this.iconObject[this.faIcon] = true;
    this.styleObject = {'small-box':true}
    this.styleObject[this.style] = true;
  }

}
